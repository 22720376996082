/* tslint:disable */
/* eslint-disable */

/**
 * Array of the SBU's that the request user is customer of.
 */
export enum SbuInfoEnum {
  Evo = 'EVO',
  Evg = 'EVG',
  Evh = 'EVH',
  Evj = 'EVJ',
  Evk = 'EVK',
  Eva = 'EVA',
  Evf = 'EVF'
}
