import { QuestionnaireDto } from '../../api/models';

export const mockQuestionnaireData: QuestionnaireDto = {
  id: '334fb2c1-7dff-47c7-bbad-942be1c2d701',
  label: 'What lubricant application are you interested in?',
  options: [
    {
      id: '745adc0d-e39e-4dae-9955-c924648d89ff',
      value: 'Gear Oil',
      label: 'What type of gear oil?',
      options: [
        {
          id: 'dfda0c6c-5b0e-4b77-8345-03b368d34127',
          value: 'Automotive/Transportation',
          description:
            'Based on your choices and for their superior shear stability, chemical purity, and thickening efficiency, we recommend the following IRGAFLO® or GLISSOPAL® products: ',
          products: [
            { id: '30060711', name: 'Glissopal® V 190' },
            { id: '30060715', name: 'Glissopal® V 1500' },
            { id: '30613982', name: 'Glissopal® V 230' },
            { id: '30613981', name: 'Glissopal® V 640' },
            { id: '30613984', name: 'Glissopal® V 500' },
            { id: '30613983', name: 'Glissopal® V 700' },
            { id: '30479682', name: 'Irgaflo® 1100 V' },
            { id: '30776270', name: 'Irgaflo® 1050 V' },
          ],
        },
        {
          id: '94c1e5de-779c-44b7-a69f-6b117e5b519b',
          value: 'Industrial',
          label: 'What is your low viscosity base stock?',
          options: [
            {
              id: 'abf69d44-48ce-47f0-8a57-6c115e0f4df1',
              value: 'Mineral Oil or PAO',
              description:
                'Based on your choices and for their high shear stability, chemical purity, and thickening efficiency, we recommend the following SYNATIVE®, or GLISSOPAL® products: ',
              products: [
                { id: '30060711', name: 'Glissopal® V 190' },
                { id: '30060715', name: 'Glissopal® V 1500' },
                { id: '30613982', name: 'Glissopal® V 230' },
                { id: '30613981', name: 'Glissopal® V 640' },
                { id: '30613984', name: 'Glissopal® V 500' },
                { id: '30613983', name: 'Glissopal® V 700' },
                { id: '30719432', name: 'Synative® EEB 45' },
              ],
            },
            {
              id: '719081ab-c57e-4c12-ac8c-339b6414e79f',
              value: 'Ester',
              description:
                'Based on your choices, we recommend the following Synative® ES esters: ',
              products: [
                { id: '30731578', name: 'Synative® ES 3200' },
                { id: '30530081', name: 'Synative® ES 3345' },
                { id: '30726456', name: 'Synative® ES 3357' },
              ],
            },
          ],
        },
      ],
    },
    {
      id: '610d84f6-d368-4382-a38a-a865899c27dd',
      value: 'Hydraulic Fluid',
      label: 'What is your low viscosity base stock?',
      options: [
        {
          id: '69273564-cda6-403c-9e8e-f15e3034b7e2',
          value: 'Mineral Oil or PAO',
          description:
            'Based on your choices, we recommend our IRGAFLO® V products for their thickening efficiency and solubility in mineral oil/PAO. Additionally, IRGAFLO® V products have excellent VI improvement. Our portfolio offers a range of shear stability indices to meet your application needs. For more info: ',
          products: [
            { id: '30785408', name: 'Irgaflo® 6200 V' },
            { id: '30644937', name: 'Irgaflo® 6000 V' },
            { id: '30480069', name: 'Irgaflo® 6100 V' },
            { id: '30485389', name: 'Irgaflo® 6300 V' },
            { id: '30686416', name: 'Irgaflo® 6305 V' },
          ],
        },
        {
          id: '02dca370-f011-4d69-b5a2-35c67ea979e6',
          value: 'Ester',
          description:
            'Based on your choices, we recommend our IRGAFLO® or SYNATIVE® ES for their solubility in ester base stocks and viscosity range. IRGAFLO® V products also have superior thickening efficiency and VI Improvement. For more info: ',
          products: [
            { id: '30731578', name: 'Synative® ES 3200' },
            { id: '30530081', name: 'Synative® ES 3345' },
            { id: '30726456', name: 'Synative® ES 3357' },
            { id: '30785791', name: 'Synative® ES 3100' },
          ],
        },
        {
          id: '801dd5f5-6ce1-4e53-b24f-91f339ae0623',
          value: 'PAG',
          description:
            'Based on your choices, we recommend our water-insoluble base stocks based on polyalkylene glycol (PAG) chemistry - BASF Breox B or Plurasafe WI series products. There are many viscosity grades of these BASF PAG base stocks available and they can be directly utilized without the need for thickening. Product information is available on our PAG webpage or upon request.',
          products: [],
        },
        {
          id: '1b34fe2a-7667-4b22-8afb-4cf7775a3758',
          value: 'Water',
          description:
            'Based on your choices, we recommend some of our polyalkylene glycol (PAG) products: Pluracol V-1075 and our Breox 75W 18000 or Breox 75W 55000. In water-based hydraulic fluids, these products are great for their excellent thickening efficiency, water solubility, and fire resistance. For more info: ',
          products: [
            { id: '30535150', name: 'Breox® 75 W 18000' },
            { id: '30535146', name: 'Breox® 75 W 55000' },
          ],
        },
      ],
    },
    {
      id: 'b960fd0c-0838-4258-b6d6-cbff7f4b2b40',
      value: 'Engine Oil',
      description:
        'Based on your choices we recommend our GLISSOPAL® for its oxidative stability, shear stability, and cold flow performance. For VI improvement, we recommend our IRGAFLO® products. For more info: ',
      products: [
        { id: '30060711', name: 'Glissopal® V 190' },
        { id: '30782126', name: 'Irgaflo® 3500 V' },
      ],
    },
    {
      id: '85486427-447f-4a48-8c80-9e0329d1ea34',
      value: 'See All',
      description:
        'To learn more about how our products can meet your application needs, feel free to browse our available data or contact us below. Product information available here: ',
      products: [
        { id: '30535146', name: 'Breox® 75 W 55000' },
        { id: '30060711', name: 'Glissopal® V 190' },
        { id: '30060715', name: 'Glissopal® V 1500' },
        { id: '30613982', name: 'Glissopal® V 230' },
        { id: '30613981', name: 'Glissopal® V 640' },
        { id: '30613984', name: 'Glissopal® V 500' },
        { id: '30613983', name: 'Glissopal® V 700' },
        { id: '30479682', name: 'Irgaflo® 1100 V' },
        { id: '30785408', name: 'Irgaflo® 6200 V' },
        { id: '30644937', name: 'Irgaflo® 6000 V' },
        { id: '30480069', name: 'Irgaflo® 6100 V' },
        { id: '30485389', name: 'Irgaflo® 6300 V' },
        { id: '30686416', name: 'Irgaflo® 6305 V' },
        { id: '30782126', name: 'Irgaflo® 3500 V' },
        { id: '30776270', name: 'Irgaflo® 1050 V' },
        { id: '30535735', name: 'Synative® ES 1200' },
        { id: '30731578', name: 'Synative® ES 3200' },
        { id: '30530081', name: 'Synative® ES 3345' },
        { id: '30726456', name: 'Synative® ES 3357' },
        { id: '30785791', name: 'Synative® ES 3100' },
        { id: '30084669', name: 'Pluracol® V 1075' },
        { id: '30719432', name: 'Synative® EEB 45' },
      ],
    },
  ],
};
