import { subDays } from 'date-fns';

import {
  NavigatorFilterIdEnum,
  ProductGroupFilterIdEnum,
  ProductListResponseDto,
  SimpleFilterIdEnum,
  SimpleProductsResponseDto,
} from '../../api';

export const mockProduct = {
  isBmbCertified: true,
  id: '123',
  name: 'MOCK Chimassorb 2020 FDL ',
  slug: 'chimassorb-2020-fdl',
  technicalName: 'Chimassorb 2020 FDL',
  alphaCode: 'ABC',
  productGroup: 'AB',
  sbu: 'EVG',
  description:
    'This is description for Chimassorb 2020 FDL, internal material number is [30486994]',
  lastPurchasedDate: '2022-09-03T01:08:32.187Z',
  buyability: true,
};

export const mockProducts = [
  {
    isBmbCertified: true,
    id: '123',
    name: 'MOCK Chimassorb 2020 FDL ',
    slug: 'chimassorb-2020-fdl',
    technicalName: 'Chimassorb 2020 FDL',
    alphaCode: 'ABC',
    productGroup: 'AB',
    sbu: 'EVG',
    description:
      'This is description for Chimassorb 2020 FDL, internal material number is [30486994]',
    lastPurchasedDate: '2022-09-03T01:08:32.187Z',
    buyability: true,
  },
  {
    isBmbCertified: true,
    id: '30501947',
    name: 'Uvinul 3030 FF',
    slug: 'uvinul-3030-ff',
    description: 'This is description for Uvinul 3030 FF, internal material number is [30501947]',
    lastPurchasedDate: subDays(new Date(), 25).toISOString(),
    technicalName: 'Uvinul 3030 FF',
    alphaCode: 'ABC',
    productGroup: 'AB',
    sbu: 'EVO',
    buyability: true,
  },
  {
    isBmbCertified: true,
    id: '30141931',
    name: 'Irganox 1035',
    slug: 'irganox-1035',
    description: 'This is description for Irganox 1035, internal material number is [30141931]',
    lastPurchasedDate: '2022-09-06T00:03:25.404Z',
    technicalName: 'Irganox 1035',
    alphaCode: 'ABC',
    productGroup: 'AB',
    sbu: 'EVJ',
    buyability: false,
  },
  {
    isBmbCertified: false,
    id: '30475161',
    name: 'Irganox 1330',
    slug: 'irganox-1330',
    description: 'This is description for Irganox 1330, internal material number is [30475161]',
    lastPurchasedDate: '2022-07-17T06:41:54.790Z',
    technicalName: 'Irganox 1330',
    alphaCode: 'ABC',
    productGroup: 'AB',
    sbu: 'EVO',
    buyability: false,
  },
  {
    isBmbCertified: false,
    id: '30482345',
    name: 'Irgastat P 16',
    slug: 'irgastat-p-16',
    description: 'This is description for Irgastat P 16, internal material number is [30482345]',
    technicalName: 'Irgastat P 16',
    alphaCode: 'ABC',
    productGroup: 'AB',
    sbu: 'EVA',
    buyability: false,
  },
  {
    isBmbCertified: false,
    id: '30070416',
    name: 'Irganox B 1171',
    slug: 'irganox-b-1171',
    description: 'This is description for Irganox B 1171, internal material number is [30070416]',
    lastPurchasedDate: '2022-06-21T12:59:52.555Z',
    technicalName: 'Irganox B 1171',
    alphaCode: 'ABC',
    productGroup: 'AB',
    sbu: 'EVJ',
    buyability: false,
  },
  {
    isBmbCertified: false,
    id: '30473476',
    name: 'Irganox B 900',
    slug: 'irganox-b-900',
    description: 'This is description for Irganox B 900, internal material number is [30473476]',
    lastPurchasedDate: '2022-08-21T17:00:10.877Z',
    technicalName: 'Irganox B 900',
    alphaCode: 'ABC',
    productGroup: 'AB',
    sbu: 'EVG',
    buyability: false,
  },
  {
    isBmbCertified: false,
    id: '30498225',
    name: 'Tinuvin XT 847 FF',
    slug: 'tinuvin-xt-847-ff',
    description:
      'This is description for Tinuvin XT 847 FF, internal material number is [30498225]',
    technicalName: 'Tinuvin XT 847 FF',
    alphaCode: 'ABC',
    productGroup: 'AB',
    sbu: 'EVG',
    buyability: false,
  },
  {
    isBmbCertified: false,
    id: '30481121',
    name: 'Irganox 565 DD',
    slug: 'irganox-565-dd',
    description: 'This is description for Irganox 565 DD, internal material number is [30481121]',
    lastPurchasedDate: '2022-07-16T16:21:32.369Z',
    technicalName: 'Irganox 565 DD',
    alphaCode: 'ABC',
    productGroup: 'AB',
    sbu: null,
    buyability: false,
  },
  {
    isBmbCertified: false,
    id: '30487023',
    name: 'Tinuvin 111 FDL',
    slug: 'tinuvin-111-fdl',
    description: 'This is description for Tinuvin 111 FDL, internal material number is [30487023]',
    technicalName: 'Tinuvin 111 FDL',
    alphaCode: 'ABC',
    productGroup: 'AB',
    sbu: 'EVG',
    buyability: false,
  },
  {
    isBmbCertified: false,
    id: '30481487',
    name: 'Melapur MC50',
    slug: 'melapur-mc50',
    description: 'This is description for Melapur MC50, internal material number is [30481487]',
    technicalName: 'Melapur MC50',
    alphaCode: 'ABC',
    productGroup: 'AB',
    sbu: 'EVG',
    buyability: false,
  },
  {
    isBmbCertified: false,
    id: '30475154',
    name: 'Irganox 565',
    slug: 'irganox-565',
    description: 'This is description for Irganox 565, internal material number is [30475154]',
    lastPurchasedDate: '2022-08-04T01:06:52.726Z',
    technicalName: 'Irganox 565',
    alphaCode: 'ABC',
    productGroup: 'AB',
    sbu: 'EVG',
    buyability: false,
  },
  {
    isBmbCertified: false,
    id: '30137471',
    name: 'Tinuvin 571',
    slug: 'tinuvin-571',
    description: 'This is description for Tinuvin 571, internal material number is [30137471]',
    technicalName: 'Tinuvin 571',
    alphaCode: 'ABC',
    productGroup: 'AB',
    sbu: 'EVG',
    buyability: false,
  },
  {
    isBmbCertified: false,
    id: '30666496',
    name: 'Irgastab PUR 70',
    slug: 'irgastab-pur-70',
    technicalName: 'Irgastab PUR 70',
    alphaCode: 'ABC',
    productGroup: 'AB',
    sbu: 'EVG',
    description: 'This is description for Irgastab PUR 70, internal material number is [30666496]',
    buyability: false,
  },
  {
    isBmbCertified: false,
    id: '30478607',
    name: 'Tinuvin 1577 ED',
    slug: 'tinuvin-1577-ed',
    technicalName: 'Tinuvin 1577 ED',
    alphaCode: 'ABC',
    productGroup: 'AB',
    sbu: 'EVG',
    description: 'This is description for Tinuvin 1577 ED, internal material number is [30478607]',
    buyability: false,
  },
  {
    isBmbCertified: false,
    id: '30066298',
    name: 'Chimassorb 944 LD',
    slug: 'chimassorb-944-ld',
    technicalName: 'Chimassorb 944 LD',
    alphaCode: 'ABC',
    productGroup: 'AB',
    sbu: 'EVG',
    description:
      'This is description for Chimassorb 944 LD, internal material number is [30066298]',
    lastPurchasedDate: '2022-08-21T15:41:13.302Z',
    buyability: false,
  },
  {
    isBmbCertified: false,
    id: '30482901',
    name: 'Tinuvin 234 FF',
    slug: 'tinuvin-234-ff',
    technicalName: 'Tinuvin 234 FF',
    alphaCode: 'ABC',
    productGroup: 'AB',
    sbu: 'EVG',
    description: 'This is description for Tinuvin 234 FF, internal material number is [30482901]',
    buyability: false,
  },
  {
    isBmbCertified: false,
    id: '30106585',
    name: 'Irganox 245 FF',
    slug: 'irganox-245-ff',
    technicalName: 'Irganox 245 FF',
    alphaCode: 'ABC',
    productGroup: 'AB',
    sbu: 'EVG',
    description: 'This is description for Irganox 245 FF, internal material number is [30106585]',
    lastPurchasedDate: '2022-08-12T08:43:44.211Z',
    buyability: false,
  },
  {
    isBmbCertified: false,
    id: '30480832',
    name: 'Irganox 1076 Melt',
    slug: 'irganox-1076-melt',
    technicalName: 'Irganox 1076 Melt',
    alphaCode: 'ABC',
    productGroup: 'AB',
    sbu: 'EVG',
    description:
      'This is description for Irganox 1076 Melt, internal material number is [30480832]',
    lastPurchasedDate: '2022-08-28T14:19:51.642Z',
    buyability: false,
  },
  {
    isBmbCertified: false,
    id: '30472148',
    name: 'Tinuvin® 783 FDL',
    slug: 'tinuvin-783-fdl',
    technicalName: 'Tinuvin® 783 FDL',
    alphaCode: 'ABC',
    productGroup: 'AB',
    sbu: 'EVG',
    description:
      'This is description for Tinuvin® 783 FDL, internal material number is [30472148]',
    buyability: false,
  },
  {
    isBmbCertified: false,
    id: '30483479',
    name: 'Irgastab FS 811 FF',
    slug: 'irgastab-fs-811-ff',
    technicalName: 'Irgastab FS 811 FF',
    alphaCode: 'ABC',
    productGroup: 'AB',
    sbu: 'EVG',
    description:
      'This is description for Irgastab FS 811 FF, internal material number is [30483479]',
    buyability: false,
  },
  {
    isBmbCertified: false,
    id: '30065709',
    name: 'Irganox 245',
    slug: 'irganox-245',
    technicalName: 'Irganox 245',
    alphaCode: 'ABC',
    productGroup: 'AB',
    sbu: 'EVG',
    description: 'This is description for Irganox 245, internal material number is [30065709]',
    lastPurchasedDate: '2022-09-10T21:04:18.391Z',
    buyability: false,
  },
  {
    isBmbCertified: false,
    id: '30477067',
    name: 'Tinuvin 360',
    slug: 'tinuvin-360',
    technicalName: 'Tinuvin 360',
    alphaCode: 'ABC',
    productGroup: 'AB',
    sbu: 'EVG',
    description: 'This is description for Tinuvin 360, internal material number is [30477067]',
    buyability: false,
  },
  {
    isBmbCertified: false,
    id: '30097245',
    name: 'Irganox 1098',
    slug: 'irganox-1098',
    technicalName: 'Irganox 1098',
    alphaCode: 'ABC',
    productGroup: 'AB',
    sbu: 'EVG',
    description: 'This is description for Irganox 1098, internal material number is [30097245]',
    lastPurchasedDate: '2022-08-19T09:39:02.729Z',
    buyability: false,
  },
  {
    isBmbCertified: false,
    id: '30482677',
    name: 'Tinuvin XT 850 FF',
    slug: 'tinuvin-xt-850-ff',
    technicalName: 'Tinuvin XT 850 FF',
    alphaCode: 'ABC',
    productGroup: 'AB',
    sbu: 'EVG',
    description:
      'This is description for Tinuvin XT 850 FF, internal material number is [30482677]',
    buyability: false,
  },
  {
    isBmbCertified: false,
    id: '30484363',
    name: 'Irgafos® 168 FF',
    slug: 'irgafos-168-ff',
    technicalName: 'Irgafos® 168 FF',
    alphaCode: 'ABC',
    productGroup: 'AB',
    sbu: 'EVG',
    description: 'This is description for Irgafos® 168 FF, internal material number is [30484363]',
    lastPurchasedDate: '2022-07-09T16:00:07.173Z',
    buyability: false,
  },
  {
    isBmbCertified: false,
    id: '30261630',
    name: 'Tinuvin® 329',
    slug: 'tinuvin-329',
    technicalName: 'Tinuvin® 329',
    alphaCode: 'ABC',
    productGroup: 'AB',
    sbu: 'EVG',
    description: 'This is description for Tinuvin® 329, internal material number is [30261630]',
    buyability: false,
  },
  {
    isBmbCertified: false,
    id: '30482427',
    name: 'Irgastab FS 410 FF',
    slug: 'irgastab-fs-410-ff',
    technicalName: 'Irgastab FS 410 FF',
    alphaCode: 'ABC',
    productGroup: 'AB',
    sbu: 'EVG',
    description:
      'This is description for Irgastab FS 410 FF, internal material number is [30482427]',
    buyability: false,
  },
  {
    isBmbCertified: false,
    id: '30208464',
    name: 'Chimassorb 944 FDL',
    slug: 'chimassorb-944-fdl',
    technicalName: 'Chimassorb 944 FDL',
    alphaCode: 'ABC',
    productGroup: 'AB',
    sbu: 'EVG',
    description:
      'This is description for Chimassorb 944 FDL, internal material number is [30208464]',
    lastPurchasedDate: '2022-08-28T17:09:01.537Z',
    buyability: false,
  },
  {
    isBmbCertified: false,
    id: '30292176',
    name: 'Irganox PS 800 FL',
    slug: 'irganox-ps-800-fl',
    technicalName: 'Irganox PS 800 FL',
    alphaCode: 'ABC',
    productGroup: 'AB',
    sbu: 'EVG',
    description:
      'This is description for Irganox PS 800 FL, internal material number is [30292176]',
    lastPurchasedDate: '2022-07-26T07:03:59.118Z',
    buyability: false,
  },
  {
    isBmbCertified: false,
    id: '30486961',
    name: 'Irgastab NA 287',
    slug: 'irgastab-na-287',
    technicalName: 'Irgastab NA 287',
    alphaCode: 'ABC',
    productGroup: 'AB',
    sbu: 'EVG',
    description: 'This is description for Irgastab NA 287, internal material number is [30486961]',
    buyability: false,
  },
  {
    isBmbCertified: false,
    id: '30474369',
    name: 'Irganox 1035 FF (W&C)',
    slug: 'irganox-1035-ff-wc',
    technicalName: 'Irganox 1035 FF (W&C)',
    alphaCode: 'ABC',
    productGroup: 'AB',
    sbu: 'EVG',
    description:
      'This is description for Irganox 1035 FF (W&C), internal material number is [30474369]',
    lastPurchasedDate: '2022-07-30T18:44:23.453Z',
    buyability: false,
  },
  {
    isBmbCertified: false,
    id: '30254367',
    name: 'Irgafos 126',
    slug: 'irgafos-126',
    technicalName: 'Irgafos 126',
    alphaCode: 'ABC',
    productGroup: 'AB',
    sbu: 'EVG',
    description: 'This is description for Irgafos 126, internal material number is [30254367]',
    lastPurchasedDate: '2022-09-09T01:42:23.666Z',
    buyability: false,
  },
  {
    isBmbCertified: false,
    id: '30551425',
    name: 'Tinuvin 1600',
    slug: 'tinuvin-1600',
    technicalName: 'Tinuvin 1600',
    alphaCode: 'ABC',
    productGroup: 'AB',
    sbu: 'EVG',
    description: 'This is description for Tinuvin 1600, internal material number is [30551425]',
    buyability: false,
  },
  {
    isBmbCertified: false,
    id: '30119547',
    name: 'Irganox® 1076',
    slug: 'irganox-1076',
    technicalName: 'Irganox® 1076',
    alphaCode: 'ABC',
    productGroup: 'AB',
    sbu: 'EVG',
    description: 'This is description for Irganox® 1076, internal material number is [30119547]',
    lastPurchasedDate: '2022-06-20T02:06:04.828Z',
    buyability: false,
  },
  {
    isBmbCertified: false,
    id: '30480989',
    name: 'Irganox E 201',
    slug: 'irganox-e-201',
    technicalName: 'Irganox E 201',
    alphaCode: 'ABC',
    productGroup: 'AB',
    sbu: 'EVG',
    description: 'This is description for Irganox E 201, internal material number is [30480989]',
    lastPurchasedDate: '2022-08-15T00:33:35.624Z',
    buyability: false,
  },
  {
    isBmbCertified: false,
    id: '30483293',
    name: 'Tinuvin 329 FF',
    slug: 'tinuvin-329-ff',
    technicalName: 'Tinuvin 329 FF',
    alphaCode: 'ABC',
    productGroup: 'AB',
    sbu: 'EVG',
    description: 'This is description for Tinuvin 329 FF, internal material number is [30483293]',
    buyability: false,
  },
  {
    isBmbCertified: false,
    id: '30475047',
    name: 'Melapur MC XL',
    slug: 'melapur-mc-xl',
    technicalName: 'Melapur MC XL',
    alphaCode: 'ABC',
    productGroup: 'AB',
    sbu: 'EVG',
    description: 'This is description for Melapur MC XL, internal material number is [30475047]',
    buyability: false,
  },
  {
    isBmbCertified: false,
    id: '30482001',
    name: 'Irgastat P 20',
    slug: 'irgastat-p-20',
    technicalName: 'Irgastat P 20',
    alphaCode: 'ABC',
    productGroup: 'AB',
    sbu: 'EVG',
    description: 'This is description for Irgastat P 20, internal material number is [30482001]',
    buyability: false,
  },
  {
    isBmbCertified: false,
    id: '30474464',
    name: 'Tinuvin NOR 371 FF',
    slug: 'tinuvin-nor-371-ff',
    technicalName: 'Tinuvin NOR 371 FF',
    alphaCode: 'ABC',
    productGroup: 'AB',
    sbu: 'EVG',
    description:
      'This is description for Tinuvin NOR 371 FF, internal material number is [30474464]',
    buyability: false,
  },
  {
    isBmbCertified: false,
    id: '30476168',
    name: 'Irgastab PUR 67',
    slug: 'irgastab-pur-67',
    technicalName: 'Irgastab PUR 67',
    alphaCode: 'ABC',
    productGroup: 'AB',
    sbu: 'EVG',
    description: 'This is description for Irgastab PUR 67, internal material number is [30476168]',
    buyability: false,
  },
  {
    isBmbCertified: false,
    id: '30472828',
    name: 'Tinuvin 326',
    slug: 'tinuvin-326',
    technicalName: 'Tinuvin 326',
    alphaCode: 'ABC',
    productGroup: 'AB',
    sbu: 'EVG',
    description: 'This is description for Tinuvin 326, internal material number is [30472828]',
    buyability: false,
  },
  {
    isBmbCertified: false,
    id: '30485581',
    name: 'Melapur MC25',
    slug: 'melapur-mc25',
    technicalName: 'Melapur MC25',
    alphaCode: 'ABC',
    productGroup: 'AB',
    sbu: 'EVG',
    description: 'This is description for Melapur MC25, internal material number is [30485581]',
    buyability: false,
  },
  {
    isBmbCertified: false,
    id: '30472106',
    name: 'Flamestab NOR 116 FF',
    slug: 'flamestab-nor-116-ff',
    technicalName: 'Flamestab NOR 116 FF',
    alphaCode: 'ABC',
    productGroup: 'AB',
    sbu: 'EVG',
    description:
      'This is description for Flamestab NOR 116 FF, internal material number is [30472106]',
    lastPurchasedDate: '2022-08-05T07:54:15.649Z',
    buyability: false,
  },
  {
    isBmbCertified: false,
    id: '30069450',
    name: 'Uvinul 3030',
    slug: 'uvinul-3030',
    technicalName: 'Uvinul 3030',
    alphaCode: 'ABC',
    productGroup: 'AB',
    sbu: 'EVG',
    description: 'This is description for Uvinul 3030, internal material number is [30069450]',
    lastPurchasedDate: '2022-09-04T04:18:25.287Z',
    buyability: false,
  },
  {
    isBmbCertified: false,
    id: '30485060',
    name: 'Tinopal OB',
    slug: 'tinopal-ob',
    technicalName: 'Tinopal OB',
    alphaCode: 'ABC',
    productGroup: 'AB',
    sbu: 'EVG',
    description: 'This is description for Tinopal OB, internal material number is [30485060]',
    buyability: false,
  },
  {
    isBmbCertified: false,
    id: '30478606',
    name: 'Tinuvin 360 ED',
    slug: 'tinuvin-360-ed',
    technicalName: 'Tinuvin 360 ED',
    alphaCode: 'ABC',
    productGroup: 'AB',
    sbu: 'EVG',
    description: 'This is description for Tinuvin 360 ED, internal material number is [30478606]',
    buyability: false,
  },
  {
    isBmbCertified: false,
    id: '30481126',
    name: 'Tinuvin 494 AR',
    slug: 'tinuvin-494-ar',
    technicalName: 'Tinuvin 494 AR',
    alphaCode: 'ABC',
    productGroup: 'AB',
    sbu: 'EVG',
    description: 'This is description for Tinuvin 494 AR, internal material number is [30481126]',
    buyability: false,
  },
  {
    isBmbCertified: false,
    id: '30503662',
    name: 'Tinuvin PA 123',
    slug: 'tinuvin-pa-123',
    technicalName: 'Tinuvin PA 123',
    alphaCode: 'ABC',
    productGroup: 'AB',
    sbu: 'EVG',
    description: 'This is description for Tinuvin PA 123, internal material number is [30503662]',
    buyability: false,
  },
  {
    isBmbCertified: false,
    id: '30490071',
    name: 'Irgaclear XT 386',
    slug: 'irgaclear-xt-386',
    technicalName: 'Irgaclear XT 386',
    alphaCode: 'ABC',
    productGroup: 'AB',
    sbu: 'EVG',
    description: 'This is description for Irgaclear XT 386, internal material number is [30490071]',
    lastPurchasedDate: '2022-07-18T03:28:36.945Z',
    buyability: false,
  },
  {
    isBmbCertified: false,
    id: '30480676',
    name: 'Tinuvin 326 FL',
    slug: 'tinuvin-326-fl',
    technicalName: 'Tinuvin 326 FL',
    alphaCode: 'ABC',
    productGroup: 'AB',
    sbu: 'EVG',
    description: 'This is description for Tinuvin 326 FL, internal material number is [30480676]',
    buyability: false,
  },
  {
    isBmbCertified: false,
    id: '30736869',
    name: 'Tinuvin NOR 356 FF',
    slug: 'tinuvin-nor-356-ff',
    technicalName: 'Tinuvin NOR 356 FF',
    alphaCode: 'ABC',
    productGroup: 'AB',
    sbu: 'EVG',
    description:
      'This is description for Tinuvin NOR 356 FF, internal material number is [30736869]',
    buyability: false,
  },
  {
    isBmbCertified: false,
    id: '30648494',
    name: 'Tinuvin XT 835 FF',
    slug: 'tinuvin-xt-835-ff',
    technicalName: 'Tinuvin XT 835 FF',
    alphaCode: 'ABC',
    productGroup: 'AB',
    sbu: 'EVG',
    description:
      'This is description for Tinuvin XT 835 FF, internal material number is [30648494]',
    buyability: false,
  },
  {
    isBmbCertified: false,
    id: '30050510',
    name: 'Tinuvin 770 DF',
    slug: 'tinuvin-770-df',
    technicalName: 'Tinuvin 770 DF',
    alphaCode: 'ABC',
    productGroup: 'AB',
    sbu: 'EVG',
    description: 'This is description for Tinuvin 770 DF, internal material number is [30050510]',
    buyability: false,
  },
  {
    isBmbCertified: false,
    id: '30413938',
    name: 'Chimassorb 81 FL',
    slug: 'chimassorb-81-fl',
    technicalName: 'Chimassorb 81 FL',
    alphaCode: 'ABC',
    productGroup: 'AB',
    sbu: 'EVG',
    description: 'This is description for Chimassorb 81 FL, internal material number is [30413938]',
    lastPurchasedDate: '2022-06-22T09:13:08.506Z',
    buyability: false,
  },
  {
    isBmbCertified: false,
    id: '30492867',
    name: 'Irganox 1425',
    slug: 'irganox-1425',
    technicalName: 'Irganox 1425',
    alphaCode: 'ABC',
    productGroup: 'AB',
    sbu: 'EVG',
    description: 'This is description for Irganox 1425, internal material number is [30492867]',
    lastPurchasedDate: '2022-08-30T00:00:45.388Z',
    buyability: false,
  },
  {
    isBmbCertified: false,
    id: '30472068',
    name: 'Irganox 3114',
    slug: 'irganox-3114',
    technicalName: 'Irganox 3114',
    alphaCode: 'ABC',
    productGroup: 'AB',
    sbu: 'EVG',
    description: 'This is description for Irganox 3114, internal material number is [30472068]',
    lastPurchasedDate: '2022-08-31T08:33:19.378Z',
    buyability: false,
  },
  {
    isBmbCertified: false,
    id: '30475046',
    name: 'Irganox B 215 FF',
    slug: 'irganox-b-215-ff',
    technicalName: 'Irganox B 215 FF',
    alphaCode: 'ABC',
    productGroup: 'AB',
    sbu: 'EVG',
    description: 'This is description for Irganox B 215 FF, internal material number is [30475046]',
    lastPurchasedDate: '2022-08-07T14:21:21.666Z',
    buyability: false,
  },
  {
    isBmbCertified: false,
    id: '30484384',
    name: 'Melapur MC15',
    slug: 'melapur-mc15',
    technicalName: 'Melapur MC15',
    alphaCode: 'ABC',
    productGroup: 'AB',
    sbu: 'EVG',
    description: 'This is description for Melapur MC15, internal material number is [30484384]',
    buyability: false,
  },
  {
    isBmbCertified: false,
    id: '30476352',
    name: 'Irgastat P 22',
    slug: 'irgastat-p-22',
    technicalName: 'Irgastat P 22',
    alphaCode: 'ABC',
    productGroup: 'AB',
    sbu: 'EVG',
    description: 'This is description for Irgastat P 22, internal material number is [30476352]',
    buyability: false,
  },
  {
    isBmbCertified: false,
    id: '30436459',
    name: 'Irganox 1425 WL',
    slug: 'irganox-1425-wl',
    technicalName: 'Irganox 1425 WL',
    alphaCode: 'ABC',
    productGroup: 'AB',
    sbu: 'EVG',
    description: 'This is description for Irganox 1425 WL, internal material number is [30436459]',
    lastPurchasedDate: '2022-08-28T19:37:21.626Z',
    buyability: false,
  },
  {
    isBmbCertified: false,
    id: '30475225',
    name: 'Irganox MD 1024',
    slug: 'irganox-md-1024',
    technicalName: 'Irganox MD 1024',
    alphaCode: 'ABC',
    productGroup: 'AB',
    sbu: 'EVG',
    description: 'This is description for Irganox MD 1024, internal material number is [30475225]',
    lastPurchasedDate: '2022-08-23T16:45:58.099Z',
    buyability: false,
  },
  {
    isBmbCertified: false,
    id: '30051837',
    name: 'Uvinul 3035',
    slug: 'uvinul-3035',
    technicalName: 'Uvinul 3035',
    alphaCode: 'ABC',
    productGroup: 'AB',
    sbu: 'EVG',
    description: 'This is description for Uvinul 3035, internal material number is [30051837]',
    lastPurchasedDate: '2022-06-27T01:04:49.329Z',
    buyability: false,
  },
  {
    isBmbCertified: false,
    id: '30215496',
    name: 'Tinuvin 312',
    slug: 'tinuvin-312',
    technicalName: 'Tinuvin 312',
    alphaCode: 'ABC',
    productGroup: 'AB',
    sbu: 'EVG',
    description: 'This is description for Tinuvin 312, internal material number is [30215496]',
    buyability: false,
  },
  {
    isBmbCertified: false,
    id: '30765224',
    name: 'Tinuvin NOR 371 AR',
    slug: 'tinuvin-nor-371-ar',
    technicalName: 'Tinuvin NOR 371 AR',
    alphaCode: 'ABC',
    productGroup: 'AB',
    sbu: 'EVG',
    description:
      'This is description for Tinuvin NOR 371 AR, internal material number is [30765224]',
    buyability: false,
  },
  {
    isBmbCertified: false,
    id: '30473478',
    name: 'Irganox B 215',
    slug: 'irganox-b-215',
    technicalName: 'Irganox B 215',
    alphaCode: 'ABC',
    productGroup: 'AB',
    sbu: 'EVG',
    description: 'This is description for Irganox B 215, internal material number is [30473478]',
    lastPurchasedDate: '2022-08-26T06:34:55.956Z',
    buyability: false,
  },
  {
    isBmbCertified: false,
    id: '30498901',
    name: 'Tinuvin B 75',
    slug: 'tinuvin-b-75',
    technicalName: 'Tinuvin B 75',
    alphaCode: 'ABC',
    productGroup: 'AB',
    sbu: 'EVG',
    description: 'This is description for Tinuvin B 75, internal material number is [30498901]',
    buyability: false,
  },
  {
    isBmbCertified: false,
    id: '30056309',
    name: 'Uvinul 3039',
    slug: 'uvinul-3039',
    technicalName: 'Uvinul 3039',
    alphaCode: 'ABC',
    productGroup: 'AB',
    sbu: 'EVG',
    description: 'This is description for Uvinul 3039, internal material number is [30056309]',
    lastPurchasedDate: '2022-07-29T06:51:10.205Z',
    buyability: false,
  },
  {
    isBmbCertified: false,
    id: '30065622',
    name: 'Tinuvin P',
    slug: 'tinuvin-p',
    technicalName: 'Tinuvin P',
    alphaCode: 'ABC',
    productGroup: 'AB',
    sbu: 'EVG',
    description: 'This is description for Tinuvin P, internal material number is [30065622]',
    buyability: false,
  },
  {
    isBmbCertified: false,
    id: '30479291',
    name: 'Irgastab FS 533 FF',
    slug: 'irgastab-fs-533-ff',
    technicalName: 'Irgastab FS 533 FF',
    alphaCode: 'ABC',
    productGroup: 'AB',
    sbu: 'EVG',
    description:
      'This is description for Irgastab FS 533 FF, internal material number is [30479291]',
    buyability: false,
  },
  {
    isBmbCertified: false,
    id: '30474952',
    name: 'Tinuvin 120',
    slug: 'tinuvin-120',
    technicalName: 'Tinuvin 120',
    alphaCode: 'ABC',
    productGroup: 'AB',
    sbu: 'EVG',
    description: 'This is description for Tinuvin 120, internal material number is [30474952]',
    buyability: false,
  },
  {
    isBmbCertified: false,
    id: '30215814',
    name: 'Irganox® 1010 FF',
    slug: 'irganox-1010-ff',
    technicalName: 'Irganox® 1010 FF',
    alphaCode: 'ABC',
    productGroup: 'AB',
    sbu: 'EVG',
    description:
      'This is description for Irganox® 1010 FF, internal material number is [30215814]',
    lastPurchasedDate: '2022-07-20T13:47:33.578Z',
    buyability: false,
  },
  {
    isBmbCertified: false,
    id: '30472796',
    name: 'Chimassorb® 81',
    slug: 'chimassorb-81',
    technicalName: 'Chimassorb® 81',
    alphaCode: 'ABC',
    productGroup: 'AB',
    sbu: 'EVG',
    description: 'This is description for Chimassorb® 81, internal material number is [30472796]',
    lastPurchasedDate: '2022-06-17T15:13:16.392Z',
    buyability: false,
  },
  {
    isBmbCertified: false,
    id: '30733375',
    name: 'Irganox B 561 ED',
    slug: 'irganox-b-561-ed',
    technicalName: 'Irganox B 561 ED',
    alphaCode: 'ABC',
    productGroup: 'AB',
    sbu: 'EVG',
    description: 'This is description for Irganox B 561 ED, internal material number is [30733375]',
    lastPurchasedDate: '2022-08-04T22:24:50.370Z',
    buyability: false,
  },
  {
    isBmbCertified: false,
    id: '30508206',
    name: 'Tinuvin XT 200 FF',
    slug: 'tinuvin-xt-200-ff',
    technicalName: 'Tinuvin XT 200 FF',
    alphaCode: 'ABC',
    productGroup: 'AB',
    sbu: 'EVG',
    description:
      'This is description for Tinuvin XT 200 FF, internal material number is [30508206]',
    buyability: false,
  },
  {
    isBmbCertified: false,
    id: '30472129',
    name: 'Melapur 200 70',
    slug: 'melapur-200-70',
    technicalName: 'Melapur 200 70',
    alphaCode: 'ABC',
    productGroup: 'AB',
    sbu: 'EVG',
    description: 'This is description for Melapur 200 70, internal material number is [30472129]',
    buyability: false,
  },
  {
    isBmbCertified: false,
    id: '30178671',
    name: 'Irganox 1098 ED',
    slug: 'irganox-1098-ed',
    technicalName: 'Irganox 1098 ED',
    alphaCode: 'ABC',
    productGroup: 'AB',
    sbu: 'EVG',
    description: 'This is description for Irganox 1098 ED, internal material number is [30178671]',
    lastPurchasedDate: '2022-07-19T03:40:54.399Z',
    buyability: false,
  },
  {
    isBmbCertified: false,
    id: '30505673',
    name: 'Irgasurf SR 100 B',
    slug: 'irgasurf-sr-100-b',
    technicalName: 'Irgasurf SR 100 B',
    alphaCode: 'ABC',
    productGroup: 'AB',
    sbu: 'EVG',
    description:
      'This is description for Irgasurf SR 100 B, internal material number is [30505673]',
    buyability: false,
  },
  {
    isBmbCertified: false,
    id: '30229687',
    name: 'Irganox B 225 FF',
    slug: 'irganox-b-225-ff',
    technicalName: 'Irganox B 225 FF',
    alphaCode: 'ABC',
    productGroup: 'AB',
    sbu: 'EVG',
    description: 'This is description for Irganox B 225 FF, internal material number is [30229687]',
    lastPurchasedDate: '2022-07-07T02:24:58.057Z',
    buyability: false,
  },
  {
    isBmbCertified: false,
    id: '30479355',
    name: 'Tinuvin PUR 866',
    slug: 'tinuvin-pur-866',
    technicalName: 'Tinuvin PUR 866',
    alphaCode: 'ABC',
    productGroup: 'AB',
    sbu: 'EVG',
    description: 'This is description for Tinuvin PUR 866, internal material number is [30479355]',
    buyability: false,
  },
  {
    isBmbCertified: false,
    id: '30480598',
    name: 'Tinuvin® 329 FL',
    slug: 'tinuvin-329-fl',
    technicalName: 'Tinuvin® 329 FL',
    alphaCode: 'ABC',
    productGroup: 'AB',
    sbu: 'EVG',
    description: 'This is description for Tinuvin® 329 FL, internal material number is [30480598]',
    buyability: false,
  },
  {
    isBmbCertified: false,
    id: '30641901',
    name: 'Tinuvin XT 55 FB',
    slug: 'tinuvin-xt-55-fb',
    technicalName: 'Tinuvin XT 55 FB',
    alphaCode: 'ABC',
    productGroup: 'AB',
    sbu: 'EVG',
    description: 'This is description for Tinuvin XT 55 FB, internal material number is [30641901]',
    buyability: false,
  },
  {
    isBmbCertified: false,
    id: '30497213',
    name: 'Irgafos 168',
    slug: 'irgafos-168',
    technicalName: 'Irgafos 168',
    alphaCode: 'ABC',
    productGroup: 'AB',
    sbu: 'EVG',
    description: 'This is description for Irgafos 168, internal material number is [30497213]',
    lastPurchasedDate: '2022-07-04T16:04:15.330Z',
    buyability: false,
  },
  {
    isBmbCertified: false,
    id: '30629676',
    name: 'Irgatec CR 76 IC',
    slug: 'irgatec-cr-76-ic',
    technicalName: 'Irgatec CR 76 IC',
    alphaCode: 'ABC',
    productGroup: 'AB',
    sbu: 'EVG',
    description: 'This is description for Irgatec CR 76 IC, internal material number is [30629676]',
    buyability: false,
  },
  {
    isBmbCertified: false,
    id: '30483175',
    name: 'Melapur 200',
    slug: 'melapur-200',
    technicalName: 'Melapur 200',
    alphaCode: 'ABC',
    productGroup: 'AB',
    sbu: 'EVG',
    description: 'This is description for Melapur 200, internal material number is [30483175]',
    buyability: false,
  },
  {
    isBmbCertified: false,
    id: '30048150',
    name: 'Irganox 1010',
    slug: 'irganox-1010',
    technicalName: 'Irganox 1010',
    alphaCode: 'ABC',
    productGroup: 'AB',
    sbu: 'EVG',
    description: 'This is description for Irganox 1010, internal material number is [30048150]',
    lastPurchasedDate: '2022-09-01T23:42:01.257Z',
    buyability: false,
  },
  {
    isBmbCertified: false,
    id: '30506847',
    name: 'Irgastat P 18 FCA',
    slug: 'irgastat-p-18-fca',
    technicalName: 'Irgastat P 18 FCA',
    alphaCode: 'ABC',
    productGroup: 'AB',
    sbu: 'EVG',
    description:
      'This is description for Irgastat P 18 FCA, internal material number is [30506847]',
    buyability: false,
  },
  {
    isBmbCertified: false,
    id: '30420888',
    name: 'Irgastab FS 301 FF',
    slug: 'irgastab-fs-301-ff',
    technicalName: 'Irgastab FS 301 FF',
    alphaCode: 'ABC',
    productGroup: 'AB',
    sbu: 'EVG',
    description:
      'This is description for Irgastab FS 301 FF, internal material number is [30420888]',
    lastPurchasedDate: '2022-07-30T17:08:04.308Z',
    buyability: false,
  },
  {
    isBmbCertified: false,
    id: '30476393',
    name: 'Tinuvin XT 855 FF',
    slug: 'tinuvin-xt-855-ff',
    technicalName: 'Tinuvin XT 855 FF',
    alphaCode: 'ABC',
    productGroup: 'AB',
    sbu: 'EVG',
    description:
      'This is description for Tinuvin XT 855 FF, internal material number is [30476393]',
    buyability: false,
  },
  {
    isBmbCertified: false,
    id: '30229846',
    name: 'Irganox PS 802 FL',
    slug: 'irganox-ps-802-fl',
    technicalName: 'Irganox PS 802 FL',
    alphaCode: 'ABC',
    productGroup: 'AB',
    sbu: 'EVG',
    description:
      'This is description for Irganox PS 802 FL, internal material number is [30229846]',
    lastPurchasedDate: '2022-07-24T08:55:27.516Z',
    buyability: false,
  },
  {
    isBmbCertified: false,
    id: '30054540',
    name: 'Irganox B 225',
    slug: 'irganox-b-225',
    technicalName: 'Irganox B 225',
    alphaCode: 'ABC',
    productGroup: 'AB',
    sbu: 'EVG',
    description: 'This is description for Irganox B 225, internal material number is [30054540]',
    lastPurchasedDate: '2022-08-20T21:51:16.729Z',
    buyability: false,
  },
  {
    isBmbCertified: false,
    id: '30503488',
    name: 'Tinuvin 622 SF',
    slug: 'tinuvin-622-sf',
    technicalName: 'Tinuvin 622 SF',
    alphaCode: 'ABC',
    productGroup: 'AB',
    sbu: 'EVG',
    description: 'This is description for Tinuvin 622 SF, internal material number is [30503488]',
    buyability: false,
  },
  {
    isBmbCertified: false,
    id: '30051845',
    name: 'Uvinul 5050 H',
    slug: 'uvinul-5050-h',
    technicalName: 'Uvinul 5050 H',
    alphaCode: 'ABC',
    productGroup: 'AB',
    sbu: 'EVG',
    description: 'This is description for Uvinul 5050 H, internal material number is [30051845]',
    lastPurchasedDate: '2022-07-29T06:40:00.398Z',
    buyability: false,
  },
  {
    isBmbCertified: false,
    id: '30501273',
    name: 'Uvinul 4050 FF',
    slug: 'uvinul-4050-ff',
    technicalName: 'Uvinul 4050 FF',
    alphaCode: 'ABC',
    productGroup: 'AB',
    sbu: 'EVG',
    description: 'This is description for Uvinul 4050 FF, internal material number is [30501273]',
    lastPurchasedDate: '2022-07-20T04:16:15.141Z',
    buyability: false,
  },
  {
    isBmbCertified: false,
    id: '30097325',
    name: 'Tinuvin 234',
    slug: 'tinuvin-234',
    technicalName: 'Tinuvin 234',
    alphaCode: 'ABC',
    productGroup: 'AB',
    sbu: 'EVG',
    description: 'This is description for Tinuvin 234, internal material number is [30097325]',
    buyability: false,
  },
  {
    isBmbCertified: false,
    id: '30140269',
    name: 'Irganox® 1076 FD',
    slug: 'irganox-1076-fd',
    technicalName: 'Irganox® 1076 FD',
    alphaCode: 'ABC',
    productGroup: 'AB',
    sbu: 'EVG',
    description:
      'This is description for Irganox® 1076 FD, internal material number is [30140269]',
    lastPurchasedDate: '2022-06-21T15:53:33.842Z',
    buyability: false,
  },
  {
    isBmbCertified: false,
    id: '30097326',
    name: 'Tinuvin 791 FB',
    slug: 'tinuvin-791-fb',
    technicalName: 'Tinuvin 791 FB',
    alphaCode: 'ABC',
    productGroup: 'AB',
    sbu: 'EVG',
    description: 'This is description for Tinuvin 791 FB, internal material number is [30097326]',
    buyability: false,
  },
  {
    isBmbCertified: false,
    id: '30482304',
    name: 'Irgastab FS 210 FF',
    slug: 'irgastab-fs-210-ff',
    technicalName: 'Irgastab FS 210 FF',
    alphaCode: 'ABC',
    productGroup: 'AB',
    sbu: 'EVG',
    description:
      'This is description for Irgastab FS 210 FF, internal material number is [30482304]',
    lastPurchasedDate: '2022-09-09T22:00:41.992Z',
    buyability: false,
  },
];

export const mockProductList: ProductListResponseDto = {
  pagination: {
    total: mockProducts.length,
    skip: 0,
    limit: 20,
  },
  products: mockProducts,
  filters: [
    {
      id: SimpleFilterIdEnum.SearchFilter,
    },
    {
      id: SimpleFilterIdEnum.MyProductsFilter,
    },
    // Note: Gonna appear just for sales users
    {
      id: SimpleFilterIdEnum.BuyabilityFilter,
    },
    {
      id: SimpleFilterIdEnum.BusinessSegmentFilter,
    },
    {
      id: NavigatorFilterIdEnum.IndustryFilter,
    },
    {
      id: ProductGroupFilterIdEnum.FunctionsFilter,
      options: [
        {
          id: '/8a8082cf84f03e4d01850a3aeec718f7/8a8082cf84f03e4d01850a3b9eae18f9',
          title: 'Acid Scavenger',
        },
        {
          id: '/8a8082cf84f03e4d01850a3aeec718f7/8a8082d486019a2901860c4bb4b81310',
          title: 'Anti Gas Migration',
        },
      ],
    },
    {
      id: ProductGroupFilterIdEnum.PolymersFilter,
      options: [
        {
          id: '/8a8082cf84f03e4d01850a44b55c1b40/8a8082cf84f03e4d01850a452a5a1b42',
          title: 'Polyamide (PA)',
        },
        {
          id: '/8a8082cf84f03e4d01850a44b55c1b40/8a8082cf84f03e4d01850a4acda51bad',
          title: 'Polycarbonate (PC)',
        },
      ],
    },
    {
      id: SimpleFilterIdEnum.BmbCertificateFilter,
    },
  ],
};

export const mockSimpleProductList: SimpleProductsResponseDto = {
  products: mockProductList.products.map(p => ({
    id: p.id,
    name: p.name,
    sbu: p.sbu,
  })),
};
