import { delay, Observable, of } from 'rxjs';

import {
  ArticlePricesDto,
  ArticleUniqueSalesAreaAvailabilityDto,
  ArticleUomDto,
  NavigatorFilterTreeDto,
  ProductDetailsDto,
  ProductListRequestBodyDto,
  ProductListResponseDto,
  ProductsApiService,
  SimpleProductsResponseDto,
  UniqueSalesAreaAvailability,
  UniqueSalesAreaAvailabilityPayload,
} from '../../api';
import { mockArticlePricesMap, mockCategoryOptions, mockProductList } from '../data';
import { mockProductDetails } from '../data/mock-product-details.data';
import { mockDocumentList } from '../data/mock-product-documents.data';

const articleUomDtos: ArticleUomDto[] = [
  {
    abbreviation: 'KG',
    decimals: 0,
    longText: 'Kilogram',
    packageSize: 300,
    uom: 'KG',
  },
  {
    abbreviation: 'LB',
    decimals: 3,
    longText: 'Pound',
    packageSize: 160.123,
    uom: 'LB',
  },
];

const localDB = {
  documentList: mockDocumentList,
  productDetails: mockProductDetails,
  categoryOptions: mockCategoryOptions,
  prices: mockArticlePricesMap,
  salesAreaAvailability: {
    uniqueSalesAreaAvailability: mockProductDetails.articles.map(
      article =>
        ({
          articleId: article.id,
          available: article.id === '50136112' ? true : false,
        }) as ArticleUniqueSalesAreaAvailabilityDto,
    ),
  },
  unitsOfMeasurement: articleUomDtos,
};

const serverDelay = 500; // ms

export const mockProductsApiService: Partial<ProductsApiService> = {
  getProductDetails(params: { id: string }): Observable<ProductDetailsDto> {
    console.log('mockProductsApiService.getProductDetails', params);
    return of(localDB.productDetails).pipe(delay(serverDelay));
  },

  getIndustriesFilterOptions: function (params?: {
    locale?: string;
  }): Observable<NavigatorFilterTreeDto> {
    console.log('mockProductsApiService.getIndustriesFilterOptions');
    return of({ industryNavigatorFilterTree: localDB.categoryOptions }).pipe(delay(serverDelay));
  },

  getProductList: function (params?: {
    body: ProductListRequestBodyDto;
  }): Observable<ProductListResponseDto> {
    console.log('mockProductsApiService.getProductList', params);
    const skip = params?.body.pagination?.skip ?? 0;
    const limit = params?.body.pagination?.limit ?? 0;
    const products = mockProductList.products.slice(skip, skip + limit);
    const pagination = { ...mockProductList.pagination, skip, limit };
    // return throwError(() => new Error('An error occurred after 1 second!'));
    return of({ ...mockProductList, products, pagination }).pipe(delay(serverDelay));
  },

  getSimpleProductsList: function (params?: {
    locale?: string;
    searchTerm?: string;
    skip: number;
    limit: number;
  }): Observable<SimpleProductsResponseDto> {
    console.log('mockProductsApiService.getSimpleProducts');
    return of({ products: mockProductList.products }).pipe(delay(serverDelay));
  },

  getArticlePrices(params: {
    articleId: string;
    locale?: string;
    payerKey?: string;
    billToKey?: string;
    shipToKey: string;
    soldToKey: string;
  }): Observable<ArticlePricesDto> {
    console.log('mockProductsApiService.getArticlePrices', params);
    const prices = localDB.prices[params.articleId];
    if (!prices) return of({ defaultCurrency: 'EUR', prices: [] });
    return of(prices).pipe(delay(serverDelay));
  },

  getUniqueSalesAreaAvailability(params: {
    body: UniqueSalesAreaAvailabilityPayload;
  }): Observable<UniqueSalesAreaAvailability> {
    console.log('mockProductsApiService.getUniqueSalesAreaAvailability', params);
    return of(localDB.salesAreaAvailability).pipe(delay(serverDelay));
  },

  getUnitOfMeasurements(params: { articleId: string }): Observable<Array<ArticleUomDto>> {
    console.log('mockProductsApiService.getUnitOfMeasurements', params);
    return of(localDB.unitsOfMeasurement).pipe(delay(serverDelay));
  },
};
