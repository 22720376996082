/* tslint:disable */
/* eslint-disable */
export enum ShipmentDocumentType {
  Inv = 'INV',
  Coa = 'COA',
  Nf = 'NF',
  Bol = 'BOL',
  Dl = 'DL',
  Dgd = 'DGD',
  Sn = 'SN'
}
