/* tslint:disable */
/* eslint-disable */
import { Injectable } from '@angular/core';
import { HttpClient, HttpResponse } from '@angular/common/http';
import { BaseService } from '../base-service';
import { ApiConfiguration } from '../api-configuration';
import { StrictHttpResponse } from '../strict-http-response';
import { RequestBuilder } from '../request-builder';
import { Observable } from 'rxjs';
import { map, filter } from 'rxjs/operators';

import { AccountBalanceResponseDto } from '../models/account-balance-response-dto';
import { AccountBalanceStatusFilterEnum } from '../models/account-balance-status-filter-enum';
import { TransactionTypesDto } from '../models/transaction-types-dto';

@Injectable({
  providedIn: 'root',
})
export class FinancialServicesApiService extends BaseService {
  constructor(
    config: ApiConfiguration,
    http: HttpClient
  ) {
    super(config, http);
  }

  /**
   * Path part for operation getAccountBalance
   */
  static readonly GetAccountBalancePath = '/api/financial-services';

  /**
   * This method provides access to the full `HttpResponse`, allowing access to response headers.
   * To access only the response body, use `getAccountBalance()` instead.
   *
   * This method doesn't expect any request body.
   */
  getAccountBalance$Response(params: {

    /**
     * Number of items to skip in pagination
     */
    offset?: number;

    /**
     * Maximum number of items to return in pagination
     */
    limit?: number;

    /**
     * The status of the account balance.
     */
    status: AccountBalanceStatusFilterEnum;

    /**
     * The start date of the period for which to retrieve financial documents.
     */
    accountingPeriodFrom: string;

    /**
     * The end date of the period for which to retrieve financial documents.
     */
    accountingPeriodTo: string;

    /**
     * The payer key for which the account balance is requested.
     */
    payerKey: string;

    /**
     * The purchase order number for which the account balance is requested.
     */
    poNumber?: string;

    /**
     * The transaction type for which the account balance is requested.
     */
    transactionType: string;
  }): Observable<StrictHttpResponse<AccountBalanceResponseDto>> {

    const rb = new RequestBuilder(this.rootUrl, FinancialServicesApiService.GetAccountBalancePath, 'get');
    if (params) {
      rb.query('offset', params.offset, {});
      rb.query('limit', params.limit, {});
      rb.query('status', params.status, {});
      rb.query('accountingPeriodFrom', params.accountingPeriodFrom, {});
      rb.query('accountingPeriodTo', params.accountingPeriodTo, {});
      rb.query('payerKey', params.payerKey, {});
      rb.query('poNumber', params.poNumber, {});
      rb.query('transactionType', params.transactionType, {});
    }

    return this.http.request(rb.build({
      responseType: 'json',
      accept: 'application/json'
    })).pipe(
      filter((r: any) => r instanceof HttpResponse),
      map((r: HttpResponse<any>) => {
        return r as StrictHttpResponse<AccountBalanceResponseDto>;
      })
    );
  }

  /**
   * This method provides access to only to the response body.
   * To access the full response (for headers, for example), `getAccountBalance$Response()` instead.
   *
   * This method doesn't expect any request body.
   */
  getAccountBalance(params: {

    /**
     * Number of items to skip in pagination
     */
    offset?: number;

    /**
     * Maximum number of items to return in pagination
     */
    limit?: number;

    /**
     * The status of the account balance.
     */
    status: AccountBalanceStatusFilterEnum;

    /**
     * The start date of the period for which to retrieve financial documents.
     */
    accountingPeriodFrom: string;

    /**
     * The end date of the period for which to retrieve financial documents.
     */
    accountingPeriodTo: string;

    /**
     * The payer key for which the account balance is requested.
     */
    payerKey: string;

    /**
     * The purchase order number for which the account balance is requested.
     */
    poNumber?: string;

    /**
     * The transaction type for which the account balance is requested.
     */
    transactionType: string;
  }): Observable<AccountBalanceResponseDto> {

    return this.getAccountBalance$Response(params).pipe(
      map((r: StrictHttpResponse<AccountBalanceResponseDto>) => r.body as AccountBalanceResponseDto)
    );
  }

  /**
   * Path part for operation downloadAccountBalanceExcel
   */
  static readonly DownloadAccountBalanceExcelPath = '/api/financial-services/download';

  /**
   * This method provides access to the full `HttpResponse`, allowing access to response headers.
   * To access only the response body, use `downloadAccountBalanceExcel()` instead.
   *
   * This method doesn't expect any request body.
   */
  downloadAccountBalanceExcel$Response(params: {

    /**
     * Number of items to skip in pagination
     */
    offset?: number;

    /**
     * Maximum number of items to return in pagination
     */
    limit?: number;

    /**
     * The status of the account balance.
     */
    status: AccountBalanceStatusFilterEnum;

    /**
     * The start date of the period for which to retrieve financial documents.
     */
    accountingPeriodFrom: string;

    /**
     * The end date of the period for which to retrieve financial documents.
     */
    accountingPeriodTo: string;

    /**
     * The payer key for which the account balance is requested.
     */
    payerKey: string;

    /**
     * The purchase order number for which the account balance is requested.
     */
    poNumber?: string;

    /**
     * The transaction type for which the account balance is requested.
     */
    transactionType: string;
  }): Observable<StrictHttpResponse<Blob>> {

    const rb = new RequestBuilder(this.rootUrl, FinancialServicesApiService.DownloadAccountBalanceExcelPath, 'get');
    if (params) {
      rb.query('offset', params.offset, {});
      rb.query('limit', params.limit, {});
      rb.query('status', params.status, {});
      rb.query('accountingPeriodFrom', params.accountingPeriodFrom, {});
      rb.query('accountingPeriodTo', params.accountingPeriodTo, {});
      rb.query('payerKey', params.payerKey, {});
      rb.query('poNumber', params.poNumber, {});
      rb.query('transactionType', params.transactionType, {});
    }

    return this.http.request(rb.build({
      responseType: 'blob',
      accept: 'application/vnd.openxmlformats-officedocument.spreadsheetml.sheet'
    })).pipe(
      filter((r: any) => r instanceof HttpResponse),
      map((r: HttpResponse<any>) => {
        return r as StrictHttpResponse<Blob>;
      })
    );
  }

  /**
   * This method provides access to only to the response body.
   * To access the full response (for headers, for example), `downloadAccountBalanceExcel$Response()` instead.
   *
   * This method doesn't expect any request body.
   */
  downloadAccountBalanceExcel(params: {

    /**
     * Number of items to skip in pagination
     */
    offset?: number;

    /**
     * Maximum number of items to return in pagination
     */
    limit?: number;

    /**
     * The status of the account balance.
     */
    status: AccountBalanceStatusFilterEnum;

    /**
     * The start date of the period for which to retrieve financial documents.
     */
    accountingPeriodFrom: string;

    /**
     * The end date of the period for which to retrieve financial documents.
     */
    accountingPeriodTo: string;

    /**
     * The payer key for which the account balance is requested.
     */
    payerKey: string;

    /**
     * The purchase order number for which the account balance is requested.
     */
    poNumber?: string;

    /**
     * The transaction type for which the account balance is requested.
     */
    transactionType: string;
  }): Observable<Blob> {

    return this.downloadAccountBalanceExcel$Response(params).pipe(
      map((r: StrictHttpResponse<Blob>) => r.body as Blob)
    );
  }

  /**
   * Path part for operation getTransactionTypes
   */
  static readonly GetTransactionTypesPath = '/api/financial-services/transaction-types';

  /**
   * This method provides access to the full `HttpResponse`, allowing access to response headers.
   * To access only the response body, use `getTransactionTypes()` instead.
   *
   * This method doesn't expect any request body.
   */
  getTransactionTypes$Response(params?: {
  }): Observable<StrictHttpResponse<TransactionTypesDto>> {

    const rb = new RequestBuilder(this.rootUrl, FinancialServicesApiService.GetTransactionTypesPath, 'get');
    if (params) {
    }

    return this.http.request(rb.build({
      responseType: 'json',
      accept: 'application/json'
    })).pipe(
      filter((r: any) => r instanceof HttpResponse),
      map((r: HttpResponse<any>) => {
        return r as StrictHttpResponse<TransactionTypesDto>;
      })
    );
  }

  /**
   * This method provides access to only to the response body.
   * To access the full response (for headers, for example), `getTransactionTypes$Response()` instead.
   *
   * This method doesn't expect any request body.
   */
  getTransactionTypes(params?: {
  }): Observable<TransactionTypesDto> {

    return this.getTransactionTypes$Response(params).pipe(
      map((r: StrictHttpResponse<TransactionTypesDto>) => r.body as TransactionTypesDto)
    );
  }

}
