import { delay, Observable, of } from 'rxjs';

import { AccountBalanceResponseDto, FinancialServicesApiService } from '../../api';
import { mockAccountBalanceResponse } from '../data';

const localDB = {
  accountBalance: mockAccountBalanceResponse,
};

const serverDelay = 500; // ms

export const mockFinancialServicesApiService: Partial<FinancialServicesApiService> = {
  getAccountBalance(params: {
    status: 'OPEN' | 'PAID' | 'ALL';

    accountingPeriodFrom: string;
    accountingPeriodTo: string;
  }): Observable<AccountBalanceResponseDto> {
    return of(localDB.accountBalance).pipe(delay(serverDelay));
  },

  downloadAccountBalanceExcel(params: {
    status: 'OPEN' | 'PAID' | 'ALL';

    accountingPeriodFrom: string;
    accountingPeriodTo: string;
  }): Observable<Blob> {
    return of().pipe(delay(serverDelay));
  },
};
