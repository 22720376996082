/* tslint:disable */
/* eslint-disable */
import { Injectable } from '@angular/core';
import { HttpClient, HttpResponse } from '@angular/common/http';
import { BaseService } from '../base-service';
import { ApiConfiguration } from '../api-configuration';
import { StrictHttpResponse } from '../strict-http-response';
import { RequestBuilder } from '../request-builder';
import { Observable } from 'rxjs';
import { map, filter } from 'rxjs/operators';

import { ArticlePricesDto } from '../models/article-prices-dto';
import { ArticleUomDto } from '../models/article-uom-dto';
import { NavigatorFilterTreeDto } from '../models/navigator-filter-tree-dto';
import { ProductDetailsDto } from '../models/product-details-dto';
import { ProductListRequestBodyDto } from '../models/product-list-request-body-dto';
import { ProductListResponseDto } from '../models/product-list-response-dto';
import { SimpleProductsResponseDto } from '../models/simple-products-response-dto';
import { UniqueSalesAreaAvailability } from '../models/unique-sales-area-availability';
import { UniqueSalesAreaAvailabilityPayload } from '../models/unique-sales-area-availability-payload';

@Injectable({
  providedIn: 'root',
})
export class ProductsApiService extends BaseService {
  constructor(
    config: ApiConfiguration,
    http: HttpClient
  ) {
    super(config, http);
  }

  /**
   * Path part for operation getProductList
   */
  static readonly GetProductListPath = '/api/products';

  /**
   * This method provides access to the full `HttpResponse`, allowing access to response headers.
   * To access only the response body, use `getProductList()` instead.
   *
   * This method sends `application/json` and handles request body of type `application/json`.
   */
  getProductList$Response(params: {
    body: ProductListRequestBodyDto
  }): Observable<StrictHttpResponse<ProductListResponseDto>> {

    const rb = new RequestBuilder(this.rootUrl, ProductsApiService.GetProductListPath, 'post');
    if (params) {
      rb.body(params.body, 'application/json');
    }

    return this.http.request(rb.build({
      responseType: 'json',
      accept: 'application/json'
    })).pipe(
      filter((r: any) => r instanceof HttpResponse),
      map((r: HttpResponse<any>) => {
        return r as StrictHttpResponse<ProductListResponseDto>;
      })
    );
  }

  /**
   * This method provides access to only to the response body.
   * To access the full response (for headers, for example), `getProductList$Response()` instead.
   *
   * This method sends `application/json` and handles request body of type `application/json`.
   */
  getProductList(params: {
    body: ProductListRequestBodyDto
  }): Observable<ProductListResponseDto> {

    return this.getProductList$Response(params).pipe(
      map((r: StrictHttpResponse<ProductListResponseDto>) => r.body as ProductListResponseDto)
    );
  }

  /**
   * Path part for operation getIndustriesFilterOptions
   */
  static readonly GetIndustriesFilterOptionsPath = '/api/products/navigator-filter-options';

  /**
   * This method provides access to the full `HttpResponse`, allowing access to response headers.
   * To access only the response body, use `getIndustriesFilterOptions()` instead.
   *
   * This method doesn't expect any request body.
   */
  getIndustriesFilterOptions$Response(params?: {
  }): Observable<StrictHttpResponse<NavigatorFilterTreeDto>> {

    const rb = new RequestBuilder(this.rootUrl, ProductsApiService.GetIndustriesFilterOptionsPath, 'get');
    if (params) {
    }

    return this.http.request(rb.build({
      responseType: 'json',
      accept: 'application/json'
    })).pipe(
      filter((r: any) => r instanceof HttpResponse),
      map((r: HttpResponse<any>) => {
        return r as StrictHttpResponse<NavigatorFilterTreeDto>;
      })
    );
  }

  /**
   * This method provides access to only to the response body.
   * To access the full response (for headers, for example), `getIndustriesFilterOptions$Response()` instead.
   *
   * This method doesn't expect any request body.
   */
  getIndustriesFilterOptions(params?: {
  }): Observable<NavigatorFilterTreeDto> {

    return this.getIndustriesFilterOptions$Response(params).pipe(
      map((r: StrictHttpResponse<NavigatorFilterTreeDto>) => r.body as NavigatorFilterTreeDto)
    );
  }

  /**
   * Path part for operation getSimpleProductsList
   */
  static readonly GetSimpleProductsListPath = '/api/products/simple-products';

  /**
   * This method provides access to the full `HttpResponse`, allowing access to response headers.
   * To access only the response body, use `getSimpleProductsList()` instead.
   *
   * This method doesn't expect any request body.
   */
  getSimpleProductsList$Response(params: {
    skip: number;
    limit: number;
    searchTerm?: string;
  }): Observable<StrictHttpResponse<SimpleProductsResponseDto>> {

    const rb = new RequestBuilder(this.rootUrl, ProductsApiService.GetSimpleProductsListPath, 'get');
    if (params) {
      rb.query('skip', params.skip, {});
      rb.query('limit', params.limit, {});
      rb.query('searchTerm', params.searchTerm, {});
    }

    return this.http.request(rb.build({
      responseType: 'json',
      accept: 'application/json'
    })).pipe(
      filter((r: any) => r instanceof HttpResponse),
      map((r: HttpResponse<any>) => {
        return r as StrictHttpResponse<SimpleProductsResponseDto>;
      })
    );
  }

  /**
   * This method provides access to only to the response body.
   * To access the full response (for headers, for example), `getSimpleProductsList$Response()` instead.
   *
   * This method doesn't expect any request body.
   */
  getSimpleProductsList(params: {
    skip: number;
    limit: number;
    searchTerm?: string;
  }): Observable<SimpleProductsResponseDto> {

    return this.getSimpleProductsList$Response(params).pipe(
      map((r: StrictHttpResponse<SimpleProductsResponseDto>) => r.body as SimpleProductsResponseDto)
    );
  }

  /**
   * Path part for operation getSimpleProductsByIds
   */
  static readonly GetSimpleProductsByIdsPath = '/api/products/simple-products/{ids}';

  /**
   * This method provides access to the full `HttpResponse`, allowing access to response headers.
   * To access only the response body, use `getSimpleProductsByIds()` instead.
   *
   * This method doesn't expect any request body.
   */
  getSimpleProductsByIds$Response(params: {
    productIds: string;
  }): Observable<StrictHttpResponse<SimpleProductsResponseDto>> {

    const rb = new RequestBuilder(this.rootUrl, ProductsApiService.GetSimpleProductsByIdsPath, 'get');
    if (params) {
      rb.query('productIds', params.productIds, {});
    }

    return this.http.request(rb.build({
      responseType: 'json',
      accept: 'application/json'
    })).pipe(
      filter((r: any) => r instanceof HttpResponse),
      map((r: HttpResponse<any>) => {
        return r as StrictHttpResponse<SimpleProductsResponseDto>;
      })
    );
  }

  /**
   * This method provides access to only to the response body.
   * To access the full response (for headers, for example), `getSimpleProductsByIds$Response()` instead.
   *
   * This method doesn't expect any request body.
   */
  getSimpleProductsByIds(params: {
    productIds: string;
  }): Observable<SimpleProductsResponseDto> {

    return this.getSimpleProductsByIds$Response(params).pipe(
      map((r: StrictHttpResponse<SimpleProductsResponseDto>) => r.body as SimpleProductsResponseDto)
    );
  }

  /**
   * Path part for operation getProductDetails
   */
  static readonly GetProductDetailsPath = '/api/products/{id}';

  /**
   * This method provides access to the full `HttpResponse`, allowing access to response headers.
   * To access only the response body, use `getProductDetails()` instead.
   *
   * This method doesn't expect any request body.
   */
  getProductDetails$Response(params: {
    id: string;
  }): Observable<StrictHttpResponse<ProductDetailsDto>> {

    const rb = new RequestBuilder(this.rootUrl, ProductsApiService.GetProductDetailsPath, 'get');
    if (params) {
      rb.path('id', params.id, {});
    }

    return this.http.request(rb.build({
      responseType: 'json',
      accept: 'application/json'
    })).pipe(
      filter((r: any) => r instanceof HttpResponse),
      map((r: HttpResponse<any>) => {
        return r as StrictHttpResponse<ProductDetailsDto>;
      })
    );
  }

  /**
   * This method provides access to only to the response body.
   * To access the full response (for headers, for example), `getProductDetails$Response()` instead.
   *
   * This method doesn't expect any request body.
   */
  getProductDetails(params: {
    id: string;
  }): Observable<ProductDetailsDto> {

    return this.getProductDetails$Response(params).pipe(
      map((r: StrictHttpResponse<ProductDetailsDto>) => r.body as ProductDetailsDto)
    );
  }

  /**
   * Path part for operation getUniqueSalesAreaAvailability
   */
  static readonly GetUniqueSalesAreaAvailabilityPath = '/api/articles/unique-sales-area-availability';

  /**
   * This method provides access to the full `HttpResponse`, allowing access to response headers.
   * To access only the response body, use `getUniqueSalesAreaAvailability()` instead.
   *
   * This method sends `application/json` and handles request body of type `application/json`.
   */
  getUniqueSalesAreaAvailability$Response(params: {
    body: UniqueSalesAreaAvailabilityPayload
  }): Observable<StrictHttpResponse<UniqueSalesAreaAvailability>> {

    const rb = new RequestBuilder(this.rootUrl, ProductsApiService.GetUniqueSalesAreaAvailabilityPath, 'post');
    if (params) {
      rb.body(params.body, 'application/json');
    }

    return this.http.request(rb.build({
      responseType: 'json',
      accept: 'application/json'
    })).pipe(
      filter((r: any) => r instanceof HttpResponse),
      map((r: HttpResponse<any>) => {
        return r as StrictHttpResponse<UniqueSalesAreaAvailability>;
      })
    );
  }

  /**
   * This method provides access to only to the response body.
   * To access the full response (for headers, for example), `getUniqueSalesAreaAvailability$Response()` instead.
   *
   * This method sends `application/json` and handles request body of type `application/json`.
   */
  getUniqueSalesAreaAvailability(params: {
    body: UniqueSalesAreaAvailabilityPayload
  }): Observable<UniqueSalesAreaAvailability> {

    return this.getUniqueSalesAreaAvailability$Response(params).pipe(
      map((r: StrictHttpResponse<UniqueSalesAreaAvailability>) => r.body as UniqueSalesAreaAvailability)
    );
  }

  /**
   * Path part for operation getArticlePrices
   */
  static readonly GetArticlePricesPath = '/api/articles/{articleId}/prices';

  /**
   * This method provides access to the full `HttpResponse`, allowing access to response headers.
   * To access only the response body, use `getArticlePrices()` instead.
   *
   * This method doesn't expect any request body.
   */
  getArticlePrices$Response(params: {
    articleId: string;
  }): Observable<StrictHttpResponse<ArticlePricesDto>> {

    const rb = new RequestBuilder(this.rootUrl, ProductsApiService.GetArticlePricesPath, 'get');
    if (params) {
      rb.path('articleId', params.articleId, {});
    }

    return this.http.request(rb.build({
      responseType: 'json',
      accept: 'application/json'
    })).pipe(
      filter((r: any) => r instanceof HttpResponse),
      map((r: HttpResponse<any>) => {
        return r as StrictHttpResponse<ArticlePricesDto>;
      })
    );
  }

  /**
   * This method provides access to only to the response body.
   * To access the full response (for headers, for example), `getArticlePrices$Response()` instead.
   *
   * This method doesn't expect any request body.
   */
  getArticlePrices(params: {
    articleId: string;
  }): Observable<ArticlePricesDto> {

    return this.getArticlePrices$Response(params).pipe(
      map((r: StrictHttpResponse<ArticlePricesDto>) => r.body as ArticlePricesDto)
    );
  }

  /**
   * Path part for operation getUnitOfMeasurements
   */
  static readonly GetUnitOfMeasurementsPath = '/api/articles/{articleId}/uom';

  /**
   * This method provides access to the full `HttpResponse`, allowing access to response headers.
   * To access only the response body, use `getUnitOfMeasurements()` instead.
   *
   * This method doesn't expect any request body.
   */
  getUnitOfMeasurements$Response(params: {
    articleId: string;
  }): Observable<StrictHttpResponse<Array<ArticleUomDto>>> {

    const rb = new RequestBuilder(this.rootUrl, ProductsApiService.GetUnitOfMeasurementsPath, 'get');
    if (params) {
      rb.path('articleId', params.articleId, {});
    }

    return this.http.request(rb.build({
      responseType: 'json',
      accept: 'application/json'
    })).pipe(
      filter((r: any) => r instanceof HttpResponse),
      map((r: HttpResponse<any>) => {
        return r as StrictHttpResponse<Array<ArticleUomDto>>;
      })
    );
  }

  /**
   * This method provides access to only to the response body.
   * To access the full response (for headers, for example), `getUnitOfMeasurements$Response()` instead.
   *
   * This method doesn't expect any request body.
   */
  getUnitOfMeasurements(params: {
    articleId: string;
  }): Observable<Array<ArticleUomDto>> {

    return this.getUnitOfMeasurements$Response(params).pipe(
      map((r: StrictHttpResponse<Array<ArticleUomDto>>) => r.body as Array<ArticleUomDto>)
    );
  }

}
